export function getIndexById(formDataMapping, id) {
    if (!formDataMapping) {
        return -1;
    }
    return formDataMapping.findIndex((mapping) => mapping.id === id);
}
export function getMappingIndexByCode(mappings, integrationType, recipientMappingCode) {
    if (!mappings) {
        return -1;
    }
    return mappings.findIndex((mapping) => mapping.recipientMappingCode === recipientMappingCode && mapping.integrationType === integrationType);
}
export function getMappingValueByCode(mappings, integrationType, recipientMappingCode) {
    const mapping = mappings.find((item) => item.recipientMappingCode === recipientMappingCode && item.integrationType === integrationType);
    return mapping ? mapping.mappingCode : '';
}
export function updateMapping(data, mappingType, key, id, formDataContent) {
    const index = getIndexById(formDataContent[mappingType][key], id);
    if (index !== -1) {
        formDataContent[mappingType][key][index].mappings = data;
    }
}
export function updateMappingCode(data, mappingType, key, id, formDataContent) {
    const index = getIndexById(formDataContent[mappingType][key], id);
    if (index !== -1) {
        formDataContent[mappingType][key][index].mappingCode = data;
    }
}
export function updateBenefitsMapping(data, mappingType, key, id, formDataContent, integrationType, recipientMappingCode) {
    const index = getIndexById(formDataContent[mappingType][key], id);
    const mappingIndex = index !== -1 ? getMappingIndexByCode(formDataContent[mappingType][key][index]?.mappings, integrationType, recipientMappingCode) : -1;
    if (index !== -1 && mappingIndex !== -1) {
        formDataContent[mappingType][key][index].mappings[mappingIndex].mappingCode = data.value;
    }
    return formDataContent;
}
export function updateIsDisabled(eventValue, mappingType, key, id, formDataContent) {
    const mappingIndex = getIndexById(formDataContent[mappingType][key], id);
    if (mappingIndex !== -1) {
        formDataContent[mappingType][key][mappingIndex].isDisabled = eventValue;
    }
}
export function getExistingBenefitMapping(data, mappingType, key, previousFormDataContent) {
    return data[mappingType][key].map((benefit) => ({
        ...benefit,
        mappings: benefit.mappings.map((mapping) => {
            const benefitIndex = getIndexById(previousFormDataContent[mappingType][key], benefit.id);
            const existingMappingCode = getMappingValueByCode(previousFormDataContent[mappingType][key][benefitIndex].mappings, mapping.integrationType, mapping.recipientMappingCode);
            return {
                ...mapping,
                mappingCode: existingMappingCode || mapping.mappingCode,
            };
        }),
    }));
}
