import SPageTitle from '@/components/ui/page-title.vue';
import SFieldText from '@/components/form/fields/text.vue';
import SFieldSelect from '@/components/form/fields/select.vue';
import SFieldDatepicker from '@/components/form/fields/datepicker.vue';
import SegicLayoutComponent from '@/mixins/segic-layout-component';
import EVENTS from 'segic_shared_front_end_utils/src/constants/events';
import { EventBus } from 'segic_shared_front_end_utils/src/utils/event-bus';
import StoreExtender from '@/store/extender';
import { isNotNullOrUndefined } from '@/utils/helpers';
import { cloneDeep, forEach } from 'lodash';
import moment from 'moment';
import MESSAGE_STATUS from 'segic_shared_front_end_utils/src/enums/message-status';
import STORE_CONSTANT_NAMES from '@/store/names';
import PlanMembersStore from '@/store/participants/planMembers';
export default {
    name: 'SearchParticipant',
    components: {
        SFieldDatepicker,
        SFieldSelect,
        SFieldText,
        SPageTitle,
    },
    mixins: [
        SegicLayoutComponent,
    ],
    data() {
        return {
            planMembersStore: null,
            outlined: true,
            clearable: true,
            formData: {
                CertificateNumber: '',
                FirstName: '',
                LastName: '',
                StartEffectiveDate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
                EndEffectiveDate: '',
                RequestReferenceNumber: '',
                RequestStatuses: null,
            },
            isSegicForm: true,
            widthDate: '175px',
            planMembers: [],
            isPlanMembersLoading: true,
        };
    },
    computed: {
        messageStatusItems() {
            const status = [];
            forEach(MESSAGE_STATUS.getValues(this.$i18n.locale), (item) => {
                if (item.Code !== MESSAGE_STATUS.CONSTANT.ACKNOWLEDGED && item.Code !== MESSAGE_STATUS.CONSTANT.ERROR) {
                    status.push(MESSAGE_STATUS.getValuePerCode(item.Code, this.$i18n.locale));
                }
            });
            return status;
        },
    },
    mounted() {
        this.getPlanMembers();
        this.$nextTick(() => {
            this.sendEventSearch(true);
        });
    },
    methods: {
        async getPlanMembers() {
            this.isPlanMembersLoading = true;
            const planMembersStore = new StoreExtender(this, STORE_CONSTANT_NAMES.PLAN_MEMBERS.LIST, PlanMembersStore);
            await planMembersStore.sendActions('get', {});
            this.planMembers = planMembersStore.getGetter('getPlanMembersSelect');
            this.isPlanMembersLoading = false;
        },
        sendEventSearch(firstLoad) {
            const formData = cloneDeep(this.formData);
            if (isNotNullOrUndefined(formData.RequestStatuses)) {
                if (formData.RequestStatuses === MESSAGE_STATUS.CONSTANT.PENDING) {
                    formData.RequestStatuses = [
                        MESSAGE_STATUS.CONSTANT.PENDING,
                        MESSAGE_STATUS.CONSTANT.ERROR,
                    ];
                }
                else {
                    formData.RequestStatuses = [formData.RequestStatuses];
                }
            }
            else {
                delete formData.RequestStatuses;
            }
            EventBus.$emit(EVENTS.SEARCH.REQUEST, formData, firstLoad);
        },
        updateParticipant(participant) {
            this.formData.CertificateNumber = participant.certificateNumber;
            this.formData.FirstName = participant.firstName;
            this.formData.LastName = participant.lastName;
        },
    },
};
