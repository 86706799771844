import SButtonMenu from '@/components/ui/button-menu.vue';
export default {
    name: 'SDownloadTemplatesIcon',
    components: { SButtonMenu },
    props: {
        templateIsLoading: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        menuItems() {
            return [
                { title: this.$t('templateIncludingAllCertificates'), key: 0 },
                { title: this.$t('templateIncludingOnlyCertificatesWithoutAMapping'), key: 1 },
            ];
        },
    },
    methods: {
        downloadTemplate(index) {
            this.$emit('downloadTemplate', index);
        },
    },
    i18n: {
        messages: {
            en: {
                templateIncludingAllCertificates: 'Template including all certificates',
                templateIncludingOnlyCertificatesWithoutAMapping: 'Template including only certificates without a mapping',
            },
            fr: {
                templateIncludingAllCertificates: 'Gabarit incluant tous les certificats',
                templateIncludingOnlyCertificatesWithoutAMapping: 'Gabarit incluant seulement les certificats non associés',
            },
        },
    },
};
