import SDatatableBase from '@/components/datatables/base.vue';
import SFieldText from '@/components/form/fields/text.vue';
import SStatus from '@/components/ui/status.vue';
import ComponentMixins from '@/mixins/segic-component';
import StepperFormMixin from '@/page-layouts/administrator/forms/add-participant/byForm/sections/mixins';
import SMappingCode from '@/page-layouts/administrator/forms/payroll-files-conversions/components/mapping-code.vue';
import SDialogLoader from '@/page-layouts/administrator/forms/payroll-files-conversions/dialog-loader.vue';
import BenefitsMappingMixin from '@/page-layouts/administrator/forms/payroll-files-conversions/utils/benefits-mapping-mixin';
import i18n from '@/page-layouts/administrator/forms/payroll-files-conversions/utils/locales';
import { getMappingValueByCode } from '@/page-layouts/administrator/forms/payroll-files-conversions/utils/mapping';
import SSettingsHeader from '@/page-layouts/administrator/forms/payroll-files-conversions/settings/settings-header.vue';
export default {
    name: 'SPageLayoutsParticipantsAddParticipantFormSectionsContactDetails',
    components: {
        SSettingsHeader,
        SStatus,
        SMappingCode,
        SDialogLoader,
        SDatatableBase,
        SFieldText,
    },
    mixins: [
        BenefitsMappingMixin,
        ComponentMixins,
        StepperFormMixin,
    ],
    props: {
        canShowMappingCodeWarning: {
            default: false,
            type: Boolean,
        },
        formData: {
            required: true,
            type: Object,
        },
        initialFormData: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            items: [],
            baseHeaders: [
                {
                    text: this.$t('taxableBenefit'),
                    value: 'name',
                },
            ],
            headers: [],
            mappingCodeHeaders: [],
            mappingType: 'taxableBenefitsMapping',
            mappingKey: 'taxableBenefits',
        };
    },
    computed: {
        mappingCodeLabel() {
            return this.$t('gainNumber');
        },
    },
    watch: {
        'formData.content.taxableBenefitsMapping': {
            handler() {
                this.headers = this.getHeaders();
                this.items = this.getItems();
            },
            deep: true,
        },
    },
    beforeMount() {
        this.headers = this.getHeaders();
        this.items = this.getItems();
    },
    methods: {
        getInitialModel(index, header) {
            if (this.initialFormData.content.taxableBenefitsMapping.useCustomMappings && this.initialFormData?.content?.taxableBenefitsMapping?.taxableBenefits?.length) {
                return getMappingValueByCode(this.initialFormData.content.taxableBenefitsMapping.taxableBenefits[index].mappings, header.integrationType, header.recipientMappingCode);
            }
            return this.formData.content.taxableBenefitsMapping.taxableBenefits[index].defaultMappingCode;
        },
        getItems() {
            if (!this.formData.content.taxableBenefitsMapping.taxableBenefits) {
                return [];
            }
            const items = this.formData.content.taxableBenefitsMapping.taxableBenefits.map(({ defaultMappingCode, description, id, mappings, prefix, }) => ({
                defaultMappingCode,
                id,
                mappings,
                name: { description: description[this.$i18n.locale] },
                prefix,
            }));
            this.addItemRecipientMappingCode(items);
            return items;
        },
        getMappingValueByCode,
    },
    i18n,
};
