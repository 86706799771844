import ComponentMixins from '@/mixins/segic-component';
import { EventBus } from 'segic_shared_front_end_utils/src/utils/event-bus';
import i18n from '../utils/locales';
export default {
    name: 'SMappingConfirmation',
    mixins: [ComponentMixins],
    props: {
        hasErrors: {
            required: true,
            type: Boolean,
        },
        isFileValid: {
            required: true,
            type: Boolean,
        },
        validationResults: {
            required: true,
            type: Array,
        },
        validationResultsLength: {
            required: true,
            type: Number,
        },
    },
    methods: {
        async handleClickConfirmMapping() {
            const bulkMappingData = this.validationResults.map(({ participant: { certificateNumber, mappingCode } }) => ({
                id: certificateNumber,
                mappingCode,
            }));
            EventBus.$emit('confirmBulkMapping', { bulkMappingData });
        },
    },
    i18n,
};
