import { PAYROLL_FILE_CONVERSION_STEPPER_TYPE } from '@/page-layouts/administrator/forms/payroll-files-conversions/utils/form.types';
import setWarnings from '@/page-layouts/administrator/forms/payroll-files-conversions/utils/warnings/setWarnings';
export default async function handleRecipientsWarnings() {
    this.formLoading = true;
    await this.payrollFilesConversionStore.sendActions('getIntegrationDataStructure', { data: this.formData.content.recipients });
    const dataStructure = this.payrollFilesConversionStore.getGetter('getIntegrationDataStructure');
    this.formData.content.isMissingMapping[PAYROLL_FILE_CONVERSION_STEPPER_TYPE.GROUP_BENEFITS_MAPPING] = dataStructure.isMissingMapping[PAYROLL_FILE_CONVERSION_STEPPER_TYPE.GROUP_BENEFITS_MAPPING];
    this.formData.content.isMissingMapping[PAYROLL_FILE_CONVERSION_STEPPER_TYPE.TAXABLE_BENEFITS_MAPPING] = dataStructure.isMissingMapping[PAYROLL_FILE_CONVERSION_STEPPER_TYPE.TAXABLE_BENEFITS_MAPPING];
    this.isAllFormStepsValid = await this.validateStep(PAYROLL_FILE_CONVERSION_STEPPER_TYPE.RECIPIENTS_MAPPING) && (!dataStructure.isMissingMapping[PAYROLL_FILE_CONVERSION_STEPPER_TYPE.GROUP_BENEFITS_MAPPING] && !dataStructure.isMissingMapping[PAYROLL_FILE_CONVERSION_STEPPER_TYPE.TAXABLE_BENEFITS_MAPPING]);
    await setWarnings.call(this);
    this.formLoading = false;
}
