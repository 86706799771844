import Q from 'q';
import { EventBus } from 'segic_shared_front_end_utils/src/utils/event-bus';
const state = {
    isLoading: true,
    promisesToLoad: [],
};
const mutations = {
    // eslint-disable-next-line no-shadow
    startLoading(state) {
        state.isLoading = true;
    },
    // eslint-disable-next-line no-shadow
    add(state, payload) {
        state.promisesToLoad.push(payload);
    },
    // eslint-disable-next-line no-shadow
    stopLoading(state) {
        state.isLoading = false;
        state.promisesToLoad = [];
        EventBus.$emit('globalLoader.loadingComplete');
    },
};
const actions = {
    add(context, payload) {
        context.commit('startLoading');
        context.commit('add', payload);
        context.dispatch('startProgress', context.state.promisesToLoad.length);
    },
    startProgress(context, count) {
        if (count !== 0) {
            Q.allSettled(context.state.promisesToLoad)
                .then(() => {
                setTimeout(() => {
                    if (count === context.state.promisesToLoad.length) {
                        context.commit('stopLoading');
                    }
                }, 100);
            });
        }
    },
};
const GlobalLoaderStore = {
    namespaced: true,
    state,
    mutations,
    actions,
};
export default GlobalLoaderStore;
