const EVENTS = {
    ADD: 'ADD',
    CANCEL: 'CANCEL',
    CHECKED_ALL: 'CHECKED_ALL',
    CLAIM_CODES: 'CLAIM_CODES',
    CLOSE: 'CLOSE',
    CONFIRM: 'CONFIRM',
    CONFIRM_DIALOG: 'confirm:dialog',
    TOGGLE_DIALOG: 'toggle:dialog',
    DELETE: 'DELETE',
    DELETE_ALL: 'DELETE_ALL',
    DOWNLOAD: 'DOWNLOAD',
    EDIT: 'EDIT',
    ERROR: 'ERROR',
    FORM_HAS_CHANGED: 'FORM_HAS_CHANGED',
    HISTORY: 'HISTORY',
    LOADING: 'LOADING',
    NEXT: 'NEXT',
    PREVIOUS: 'PREVIOUS',
    REFRESH: 'REFRESH',
    SEARCH: {
        PARTICIPANTS: 'SEARCH_PARTICIPANTS',
        REQUEST: 'SEARCH_REQUEST',
        REFRESH: 'REFRESH',
        HEALTH_SERVICE: 'HEALTH_SERVICE',
    },
    SNACKBAR: 'SNACKBAR',
    SUBMIT: 'SUBMIT',
    SUCCESS: 'SUCCESS',
    TOGGLE: {
        PARTICIPANT_HEADER_FILTERS: 'PARTICIPANT_HEADER_FILTERS',
        PARTICIPANT_LIST_FILTERS: 'PARTICIPANT_LIST_FILTERS',
        PARTICIPANT_LIST_FILTER_BTN: 'PARTICIPANT_LIST_FILTER_BTN',
        CARES_LIST: 'CARES_LIST',
    },
    VIEW: 'VIEW',
    BENEFITS_GROUPING_MASTER_BENEFIT_WILL_TERMINATE: 'benefitsGroupingMasterBenefitWillTerminate',
    CATEGORY_WILL_TERMINATE: 'categoryWillTerminate',
    DIVISION_WILL_TERMINATE: 'divisionWillTerminate',
    PARTICIPANT_BENEFITS_AUTO_ASSIGN: 'participantBenefitsAutoAssign',
};
export default EVENTS;
