import AttachmentsErrorCodes from '@/store/change-requests-attachments/error-codes';
import StoreExtender from '@/store/extender';
import { setChangesObject, checkHasDifferences } from 'segic_shared_front_end_utils/src/utils/data-compare';
import { EventBus } from 'segic_shared_front_end_utils/src/utils/event-bus';
import { cloneDeep, filter, find, forEach, merge, set, } from 'lodash';
import Q from 'q';
import EVENTS from 'segic_shared_front_end_utils/src/constants/events';
import MESSAGE_TYPE from 'segic_shared_front_end_utils/src/constants/message-types';
import { isNotNullOrUndefined, isObject } from 'segic_shared_front_end_utils/src/utils/types';
export default {
    beforeMount() {
        this.resetFormData();
    },
    beforeDestroy() {
        this.isBeforeCreation = false;
    },
    props: {
        overwriteData: {
            default: null,
        },
        id: {
            default: null,
        },
        overwriteDataLoading: {
            default: false,
        },
    },
    watch: {
        overwriteData: {
            handler() {
                if (!this.formLoading) {
                    this.$nextTick(() => {
                        this.resetForm();
                    });
                }
            },
            immediate: true,
        },
        formData: {
            handler() {
                checkHasDifferences(this.defaultData, this.formData, this.initialFormData, this.formHasChangedValues);
                this.formHasChangedValues = merge({}, this.formHasChangedValues);
                if (this.formDataDebugging) {
                    console.log('FormData Watcher ::::::');
                    console.log('initialFormData', cloneDeep(this.initialFormData));
                    console.log('formData', cloneDeep(this.formData));
                    console.log('defaultData', cloneDeep(this.defaultData));
                    console.log('formHasChangedValues', cloneDeep(this.formHasChangedValues));
                }
                this.$emit('FORM_CHANGE', this.formChanged());
            },
            deep: true,
        },
    },
    data() {
        return {
            store: null,
            formData: {},
            initialFormData: {},
            defaultData: {},
            formHasChangedValues: {},
            isBeforeCreation: false,
            formDataDebugging: false,
            formLoading: false,
            formFieldsFlags: {},
            formSendDataHasChanged: true,
        };
    },
    methods: {
        initializeStore() {
            this.createFormStore();
            if (typeof this.initializeMoreStores !== 'undefined') {
                this.initializeMoreStores();
            }
        },
        createFormStore() {
            const storeData = {};
            if (this.uriParam) {
                storeData[this.uriParam] = this.id;
            }
            this.store = new StoreExtender(this, this.storeConstant.name, this.storeConstant.template);
        },
        formChanged() {
            let hasChanged = this.formHasChangedValues.content.newValue;
            if (this.id) {
                hasChanged = this.formHasChangedValues.hasChanged;
            }
            else if (typeof this.formHasChangedValues.content.newValue === 'object') {
                hasChanged = this.formHasChangedValues.content.newValue.hasChanged;
            }
            return hasChanged;
        },
        updateField(key, value) {
            this.formData = { ...set(this.formData, key, isNotNullOrUndefined(value) ? value : null) };
        },
        mergeData(formDataRef, overwriteData, debug) {
            if (overwriteData) {
                if (debug) {
                    console.log('**********', formDataRef, overwriteData);
                }
                // eslint-disable-next-line consistent-return
                forEach(overwriteData, (value, key) => {
                    if (typeof formDataRef === 'string') {
                        return false;
                    }
                    if (typeof formDataRef[key] !== 'undefined') {
                        if (isObject(value)) {
                            if (debug) {
                                console.log('object', formDataRef[key], value);
                            }
                            this.mergeData(formDataRef[key], value, debug);
                        }
                        else {
                            if (debug) {
                                console.log('else', formDataRef, key, value);
                            }
                            formDataRef[key] = value;
                        }
                    }
                    else {
                        formDataRef[key] = value;
                        if (debug) {
                            console.log('else ***************', formDataRef, overwriteData, key);
                        }
                    }
                });
            }
        },
        cancelDialog() {
            if (this.formHasChangedValues.hasChanged) {
                this.$emit('toggleConfirmClose');
            }
            else {
                this.resetForm();
                this.$emit(EVENTS.CLOSE);
            }
        },
        submitDialog() {
            this.sendRequest();
        },
        resetForm() {
            if (this.$refs.observer) {
                this.$refs.observer.reset();
            }
            if (typeof this.resetFormData === 'function') {
                this.resetFormData();
                this.mergeData(this.defaultData, this.overwriteData);
                this.formHasChangedValues = setChangesObject(this.defaultData, this.formHasChangedValues);
                this.formData = cloneDeep(this.defaultData);
                this.initialFormData = cloneDeep(this.defaultData);
                this.isBeforeCreation = true;
            }
            else {
                console.warn('missing resetFormData()');
            }
        },
        sendRequest() {
            this.$refs.observer.validate().then((valid) => {
                if (valid) {
                    this.sendData();
                }
            });
        },
        payloadData(formData) {
            return typeof this.formatFormData === 'function' ? this.formatFormData(formData) : formData;
        },
        sendData() {
            this.formLoading = true;
            const payload = {
                data: this.payloadData(this.formData),
            };
            if (typeof this.setSubmitParams === 'function') {
                payload.params = this.setSubmitParams();
            }
            let action = 'create';
            if (this.id) {
                action = 'update';
            }
            this.emitLoading(true);
            let alertData = { message: '', type: 'success' };
            let promises = [];
            if (this.formSendDataHasChanged) {
                promises.push(this.store.sendActions(action, payload));
            }
            if (typeof this.moreStores !== 'undefined') {
                promises = this.moreStores(promises);
            }
            Q.allSettled(promises).done((results) => {
                const allSuccess = filter(results, ['state', 'fulfilled']).length === promises.length;
                let response = null;
                let closeModal = true;
                if (allSuccess) {
                    response = results[0].value;
                    alertData.message = response.message;
                    alertData.type = response.messageType;
                    if (typeof this.extraSnackbarData === 'function') {
                        alertData.extraMessage = this.extraSnackbarData(response.data);
                    }
                    if (!this.callbackForm) {
                        EventBus.$emit(EVENTS.SNACKBAR, alertData);
                    }
                    if (this.callbackForm === 'undefined') {
                        this.emitSuccess(response);
                    }
                }
                else {
                    response = find(results, ['state', 'rejected']).reason;
                    if (response.internalCode === AttachmentsErrorCodes.VIRUS) {
                        closeModal = false;
                        alertData = {
                            message: this.setMessageFileError(response, payload.data.attachments, 'api.file.virusModified'),
                            type: MESSAGE_TYPE.WARNING,
                            dismissible: true,
                        };
                    }
                    else if (response.messageType === MESSAGE_TYPE.ERROR) {
                        alertData = {
                            message: this.$t('api.generic.error'),
                            type: MESSAGE_TYPE.ERROR,
                        };
                    }
                    this.$emit('ERROR', response);
                    this.formLoading = false;
                    if (typeof this.callbackFormError === 'function') {
                        this.callbackFormError(response);
                        return;
                    }
                }
                if (typeof this.callbackForm === 'function') {
                    const callbackFormResponse = results.length !== 0 ? results[0].value : null;
                    this.callbackForm(callbackFormResponse, alertData, payload.data.attachments, this.id === null, closeModal, response);
                }
                else {
                    this.emitClose();
                    this.emitLoading(false);
                }
            });
        },
        emitClose() {
            this.resetForm();
            this.formLoading = false;
            this.$emit(EVENTS.CLOSE);
        },
        emitSuccess(response) {
            this.$emit(EVENTS.SUCCESS, response);
            this.formLoading = false;
            this.emitLoading(this.formLoading);
        },
        emitLoading(isLoading) {
            this.$emit(EVENTS.LOADING, isLoading);
        },
        validationOnUpdate(data) {
            this.formFieldsFlags = { ...set(this.formFieldsFlags, data.name, data.flags) };
        },
    },
};
