import SDatatableBase from '@/components/datatables/base.vue';
import SCheckbox from '@/components/form/fields/checkbox.vue';
import ComponentMixins from '@/mixins/segic-component';
import StepperFormMixin from '@/page-layouts/administrator/forms/add-participant/byForm/sections/mixins';
import SMappingCode from '@/page-layouts/administrator/forms/payroll-files-conversions/components/mapping-code.vue';
import SDialogLoader from '@/page-layouts/administrator/forms/payroll-files-conversions/dialog-loader.vue';
import i18n from '@/page-layouts/administrator/forms/payroll-files-conversions/utils/locales';
import { updateMappingCode, } from '@/page-layouts/administrator/forms/payroll-files-conversions/utils/mapping';
import { EventBus } from 'segic_shared_front_end_utils/src/utils/event-bus';
import { isNullOrUndefined } from '@/utils/helpers';
import { cloneDeep } from 'lodash';
import SCertificateNumbersSettingsHeader from './certificate-numbers-settings-header.vue';
export default {
    name: 'SPageLayoutsParticipantsAddParticipantFormSectionsContactDetails',
    components: {
        SMappingCode,
        SDatatableBase,
        SDialogLoader,
        SCertificateNumbersSettingsHeader,
        SCheckbox,
    },
    mixins: [
        ComponentMixins,
        StepperFormMixin,
    ],
    props: {
        canShowMappingCodeWarning: {
            default: false,
            type: Boolean,
        },
        formData: {
            required: true,
            type: Object,
        },
        initialFormData: {
            required: true,
            type: Object,
        },
    },
    data() {
        return {
            items: [],
            formattedParticipantsItems: [],
            showOnlyUnmappedParticipants: false,
            previousMappingCodes: [],
        };
    },
    computed: {
        headers() {
            return [
                {
                    text: this.$t('certificate'),
                    value: 'certificate',
                },
                {
                    text: `${this.$t('lastName')}, ${this.$t('firstName')}`,
                    value: 'fullName',
                },
                {
                    text: this.$t('division'),
                    value: 'division',
                },
                {
                    text: this.$t('employeeNumber'),
                    value: 'mappingCode',
                },
            ];
        },
    },
    watch: {
        'formData.content.participantsMapping': {
            handler() {
                this.items = this.getItems();
            },
            deep: true,
        },
        showOnlyUnmappedParticipants() {
            this.items = this.formattedParticipantsItems.filter((_, index) => this.filterByUnmappedParticipants(index));
        },
    },
    async beforeMount() {
        EventBus.$on('confirmBulkMapping', this.handleBulkMappingResults);
        this.items = this.getItems();
    },
    methods: {
        async dispatchEvent({ value, id }) {
            if (!isNullOrUndefined(value)) {
                const formDataContent = cloneDeep(this.formData.content);
                updateMappingCode(value, 'participantsMapping', 'participants', id, formDataContent);
                this.$emit('update:model', 'participantsMapping', formDataContent.participantsMapping);
                this.$nextTick(async () => {
                    const isFormValid = await this.validateForm(true);
                    this.emitEnabledNextBtn(this.stepIndex, isFormValid);
                });
            }
        },
        filterByUnmappedParticipants(index) {
            if (!this.initialFormData.content?.participantsMapping?.participants?.length) {
                return true;
            }
            return (this.showOnlyUnmappedParticipants && !this.initialFormData.content.participantsMapping.participants[index]?.mappingCode) || !this.showOnlyUnmappedParticipants;
        },
        filterItems(value) {
            this.showOnlyUnmappedParticipants = value;
        },
        formatParticipants(participants) {
            return participants.map(({ certificateNumber, division, fullName, id, mappingCode, }) => ({
                certificate: certificateNumber,
                division,
                fullName,
                mappingCode: { mappingCode, id },
            }));
        },
        getAllMappingCodesExceptId(id) {
            return this.formData.content.participantsMapping.participants.filter((mappingCodeObject) => id !== mappingCodeObject.id).map((participant) => participant.mappingCode);
        },
        getItems() {
            if (!this.formData.content?.participantsMapping?.participants) {
                return [];
            }
            this.formattedParticipantsItems = this.formatParticipants(this.formData.content.participantsMapping.participants);
            return this.formattedParticipantsItems.filter((_, index) => this.filterByUnmappedParticipants(index));
        },
        getPreviousMappingCodes() {
            return this.formData.content.participantsMapping.participants.map((participant) => participant.mappingCode);
        },
        async handleBulkMappingResults({ bulkMappingData }) {
            const formDataContent = cloneDeep(this.formData.content);
            bulkMappingData.forEach((mapping) => {
                updateMappingCode(mapping.mappingCode, 'participantsMapping', 'participants', mapping.id, formDataContent);
            });
            await this.$nextTick();
            await this.validateForm();
            this.$emit('update:model', 'participantsMapping', formDataContent.participantsMapping);
        },
        handleToggleUseCertificateNumbers(value) {
            this.formData.content.participantsMapping.useCertificateNumber = value;
            this.updateCertificateNumbersWithSegicCertificateNumbers(value);
            this.$nextTick(async () => {
                await this.validateForm();
            });
        },
        recoverPreviousMappingCodes() {
            return this.formData.content.participantsMapping.participants.map((participant, i) => ({
                ...participant,
                mappingCode: this.previousMappingCodes[i],
            }));
        },
        replaceMappingCodesWithCertificateNumbers() {
            return this.formData.content.participantsMapping.participants.map((participant) => ({
                ...participant,
                mappingCode: participant.certificateNumber,
            }));
        },
        updateCertificateNumbersWithSegicCertificateNumbers(value) {
            if (value) {
                this.previousMappingCodes = this.getPreviousMappingCodes();
                this.formData.content.participantsMapping.participants = this.replaceMappingCodesWithCertificateNumbers();
            }
            else {
                this.formData.content.participantsMapping.participants = this.recoverPreviousMappingCodes();
            }
            this.$emit('update:model', 'participantsMapping', this.formData.content.participantsMapping);
        },
    },
    i18n,
};
