import SComponentDialog from '@/components/dialogs/dialog.vue';
import ComponentMixins from '@/mixins/segic-component';
import DialogConfirmCancelMixin from '@/page-layouts/administrator/dialogs/confirm-cancel';
import SPageLayoutsParticipantAddParticipantForm from '@/page-layouts/administrator/forms/add-participant/byForm/form.vue';
import i18n from '@/page-layouts/administrator/forms/payroll-files-conversions/utils/locales';
import SPayrollFilesConversionsCreationForm from './create-mapping-form/create-mapping-form.vue';
import SPayrollFilesConversionsEditionForm from './edit-mapping-form/edit-mapping-form.vue';
export default {
    name: 'SPageLayoutsParticipantsAddParticipantDialog',
    components: {
        SPageLayoutsParticipantAddParticipantForm,
        SComponentDialog,
        SPayrollFilesConversionsCreationForm,
        SPayrollFilesConversionsEditionForm,
    },
    mixins: [ComponentMixins, DialogConfirmCancelMixin],
    props: {
        isCreate: {
            required: true,
            type: Boolean,
        },
        showPayrollFilesConversionsDialog: {
            required: true,
            type: Boolean,
        },
    },
    i18n,
};
