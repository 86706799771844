import i18n from '@/page-layouts/administrator/forms/payroll-files-conversions/utils/locales';
export default {
    name: 'ResetWarningAlert',
    props: {
        showAlert: {
            type: Boolean,
            required: true,
        },
    },
    i18n,
};
