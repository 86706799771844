import { i18n } from '@/utils/data-formatting/display/i18n';
import { date } from '@/utils/data-formatting/service-input/dates';
import { i18nEmpty } from '@/utils/data-formatting/service-input/i18n';
import { displayAddress, displayPostalCode, isDateSameOrAfter } from '@/utils/helpers';
import Provinces from '@/utils/provinces';
import { find, isEmpty, orderBy } from 'lodash';
export default {
    input(data) {
        data.Person.BirthDate = date(data.Person.BirthDate);
        data.HireDate = date(data.HireDate);
        data.Person.FullName = `${data.Person.FirstName} ${data.Person.LastName}`;
        data.addresses = orderBy(data.Addresses.map((address) => ({
            id: address.Id,
            civicAddress: address.Street,
            city: address.City,
            postalCode: displayPostalCode(address.PostalCode),
            provinceId: address.ProvinceID,
            provinceCode: Provinces[address.ProvinceID].Code,
            effectiveDate: date(address.EffectiveDate),
            isRecordCanceled: address.IsRecordCanceled,
            country: 'Canada',
        })), ['effectiveDate']);
        const currentAddress = find(orderBy(data.addresses, ['effectiveDate'], ['desc']), (address) => isDateSameOrAfter(address.effectiveDate));
        data.currentAddress = currentAddress || data.addresses[0];
        if (data.currentAddress) {
            data.currentAddress.actualValue = {
                civicAddress: data.currentAddress.civicAddress,
                city: data.currentAddress.city,
                postalCode: data.currentAddress.postalCode,
                provinceId: data.currentAddress.provinceId,
            };
        }
        data.CivilStatus = data.CivilStatus ? i18n(data.CivilStatus) : i18nEmpty();
        if (data.CohabitationDate) {
            data.CohabitationDate = date(data.CohabitationDate);
        }
        delete data.Addresses;
        return data;
    },
    display(data) {
        if (isEmpty(data)) {
            return data;
        }
        if (data.currentAddress) {
            data.currentAddress.display = data.currentAddress ? displayAddress(data.currentAddress.actualValue) : '';
        }
        else {
            data.currentAddress = {
                display: '',
            };
        }
        return data;
    },
};
