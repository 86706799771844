import i18n from '@/page-layouts/administrator/forms/payroll-files-conversions/utils/locales';
import SCertificateNumbersMappingBulkDialog from '../certificate-numbers-bulk-mapping/index.vue';
import SSettingsSwitch from './settings-switch.vue';
export default {
    name: 'SCertificateSettings',
    components: {
        SSettingsSwitch,
        SCertificateNumbersMappingBulkDialog,
    },
    props: {
        label: {
            type: String,
            required: true,
        },
        switchIsActive: {
            type: Boolean,
            required: true,
        },
        tag: {
            type: String,
            required: true,
        },
        tooltip: {
            type: String,
            default: '',
        },
    },
    methods: {
        handleToggle(value) {
            this.$emit('handleToggle', value);
        },
    },
    i18n,
};
