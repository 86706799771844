import { render, staticRenderFns } from "./edit-mapping-form.vue?vue&type=template&id=53ec9e8d&"
import script from "./edit-mapping-form.vue?vue&type=script&lang=ts&"
export * from "./edit-mapping-form.vue?vue&type=script&lang=ts&"
import style0 from "./edit-mapping-form.vue?vue&type=style&index=0&id=53ec9e8d&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
installComponents(component, {VTab,VTabItem,VTabs,VTabsItems,VTabsSlider})
