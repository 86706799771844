import HTTP_CODES from 'segic_shared_front_end_utils/src/constants/http-codes';
import VALIDATION_CODES from './validation-codes';
const FR = {
    get: {
        [HTTP_CODES.OK]: '',
    },
    getByID: {
        [VALIDATION_CODES.MAPPING_CODES_MISSING]: 'Impossible de télécharger ce fichier — Une action est requise dans la configuration.',
    },
};
const EN = {
    get: {
        [HTTP_CODES.OK]: '',
    },
    getByID: {
        [VALIDATION_CODES.MAPPING_CODES_MISSING]: 'Unable to download this file — Action required in the configuration.',
    },
};
export default {
    fr: FR,
    en: EN,
};
