import StoreBasics from '@/store/basics';
import storeUtils from '@/store/utils';
import { formatAPIResponseForDownload } from 'segic_shared_front_end_utils/src/utils/download-file';
import { outputFormData } from 'segic_shared_front_end_utils/src/utils/output-FormData';
import entities from './entities';
import Service from './service';
const cacheDelay = -1;
const cacheBust = true;
const storeData = [
    {
        key: 'data',
        defaultValue: null,
        methods: [
            {
                key: 'create',
                params: ['clientGuid', 'groupGuid', 'data'],
                cacheDelay,
                outputFormatting(data) {
                    return entities.output(data);
                },
            },
            {
                key: 'delete',
                params: ['clientGuid', 'groupGuid', 'data'],
                cacheDelay,
            },
            {
                key: 'getIntegrationDataStructure',
                params: ['clientGuid', 'groupGuid', 'data'],
                cacheBust,
                outputFormatting(data) {
                    return entities.getIntegrationDataStructure(data);
                },
                inputFormatting(state, stateKey, data) {
                    state[stateKey].value = entities.input(data);
                },
            },
            {
                key: 'get',
                params: ['clientGuid', 'groupGuid'],
                cacheBust,
                inputFormatting(state, stateKey, data) {
                    state[stateKey].value = entities.input(data);
                },
            },
            {
                key: 'getByID',
                params: ['clientGuid', 'groupGuid', 'integrationGuid'],
                cacheBust,
                inputFormatting(state, stateKey, data, payload) {
                    state[stateKey].value = formatAPIResponseForDownload(data, payload);
                },
            },
        ],
        service: Service,
    },
    {
        key: 'templateFile',
        defaultValue: null,
        methods: [
            {
                key: 'getTemplate',
                params: ['clientGuid', 'groupGuid'],
                cacheBust,
                inputFormatting(state, stateKey, data, payload) {
                    state[stateKey].value = formatAPIResponseForDownload(data, payload);
                },
            },
        ],
        service: Service,
    },
    {
        key: 'configurationStatus',
        defaultValue: null,
        methods: [
            {
                key: 'getConfigurationStatus',
                params: ['clientGuid', 'groupGuid'],
                cacheBust,
                inputFormatting(state, stateKey, data) {
                    state[stateKey].value = data;
                },
            },
        ],
        service: Service,
    },
    {
        key: 'validate',
        defaultValue: null,
        methods: [
            {
                key: 'validate',
                params: ['clientGuid', 'groupGuid', 'data'],
                cacheDelay,
                inputFormatting(state, stateKey, data) {
                    state[stateKey].value = data;
                },
                outputFormatting(data) {
                    return outputFormData(data);
                },
            },
        ],
        service: Service,
    },
];
const state = storeUtils.mergeStates(StoreBasics.statesFromData(storeData), {
    isInitialConfiguration: false,
});
const getters = storeUtils.mergeGetters(StoreBasics.gettersFromData(storeData), {
    // eslint-disable-next-line no-shadow
    isInitialConfiguration: (state) => state.isInitialConfiguration,
});
const mutations = storeUtils.mergeMutations(StoreBasics.mutationsFromData(storeData), {
    // eslint-disable-next-line no-shadow
    setIsInitialConfiguration(state, value) {
        state.isInitialConfiguration = value;
    },
});
const actions = storeUtils.mergeActions(StoreBasics.actionsFromData(storeData), {
    updateIsInitialConfiguration({ commit }, { data }) {
        commit('setIsInitialConfiguration', data.value);
    },
});
const Onboarding = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
export default Onboarding;
