import SButtonSubmit from '@/components/ui/btn-submit.vue';
import i18n from '@/page-layouts/administrator/forms/payroll-files-conversions/utils/locales';
export default {
    components: { SButtonSubmit },
    props: {
        disabledSubmitBtn: {
            type: Boolean,
            required: true,
        },
        formHasChangedValues: {
            type: Object,
            required: true,
        },
        formLoading: {
            type: Boolean,
            required: true,
        },
        nameE2E: {
            type: String,
            required: true,
        },
    },
    methods: {
        handleSendData() {
            this.$emit('handleSendData');
        },
        handleCancel(hasChanged) {
            this.$emit('handleCancel', hasChanged);
        },
    },
    i18n,
};
