import SStepper from '@/components/ui/stepper.vue';
import FormMixin from '@/mixins/form';
import storeMixin from '@/mixins/page-layout/store';
import SegicLayoutComponent from '@/mixins/segic-layout-component';
import DialogConfirmCancelMixin from '@/page-layouts/administrator/dialogs/confirm-cancel';
import SDialogConfirmCancel from '@/page-layouts/administrator/dialogs/confirm-cancel.vue';
import SDialogHeader from '@/page-layouts/administrator/forms/payroll-files-conversions/dialog-header.vue';
import SDialogLoader from '@/page-layouts/administrator/forms/payroll-files-conversions/dialog-loader.vue';
import getMappingStepsComponents from '@/page-layouts/administrator/forms/payroll-files-conversions/utils/getMappingStepsComponents';
import i18n from '@/page-layouts/administrator/forms/payroll-files-conversions/utils/locales';
import { getExistingBenefitMapping } from '@/page-layouts/administrator/forms/payroll-files-conversions/utils/mapping';
import StoreExtender from '@/store/extender';
import STORE_CONSTANT_NAMES from '@/store/names';
import PayrollFilesConversion from '@/store/payroll-files-conversion';
import { cloneDeep, get, isEqual } from 'lodash';
import EVENTS from 'segic_shared_front_end_utils/src/constants/events';
import LANGUAGE_CODES from 'segic_shared_front_end_utils/src/enums/language-codes';
import { EventBus } from 'segic_shared_front_end_utils/src/utils/event-bus';
import { isNotNullOrUndefined } from 'segic_shared_front_end_utils/src/utils/types';
import { PAYROLL_FILE_CONVERSION_STEPPER_TYPE, PAYROLL_INTEGRATION_TYPES } from '../utils/form.types';
import SCreateMappingFormStepperControls from './create-mapping-form-stepper-controls.vue';
export default {
    name: 'SPageLayoutsParticipantsAddParticipantForm',
    components: {
        SDialogHeader,
        SCreateMappingFormStepperControls,
        SDialogLoader,
        SStepper,
        SDialogConfirmCancel,
    },
    mixins: [
        SegicLayoutComponent,
        FormMixin,
        storeMixin,
        DialogConfirmCancelMixin,
    ],
    props: {
        showPayrollFilesConversionsDialog: {
            required: true,
            type: Boolean,
        },
    },
    data() {
        return {
            isPostLoading: false,
            isGetLoading: false,
            isAllFormStepsValid: true,
            payrollFilesConversionStore: null,
            storeConstant: {
                name: STORE_CONSTANT_NAMES.PAYROLL_SYSTEM_CONVERSION.SINGLE,
                template: PayrollFilesConversion,
            },
        };
    },
    computed: {
        canCancel() {
            return this.formHasChangedValues.hasChanged && !this.formData.isFormSaved;
        },
        isAllPayrollIntegrationsSetToNone() {
            return this?.formData?.content?.recipients?.length > 0 && !this.formData?.content?.recipients.every(({ integrationType }) => integrationType === PAYROLL_INTEGRATION_TYPES.NONE);
        },
    },
    watch: {
        'formData.content.recipients': {
            handler(newValue, oldValue) {
                if (!isEqual(newValue, oldValue) && !isEqual(this.formData.previousFormDataContent.recipients, this.formData.content.recipients)) {
                    EventBus.$emit('setHasReachLastStep', false);
                }
            },
            deep: true,
        },
    },
    async beforeMount() {
        this.payrollFilesConversionStore = new StoreExtender(this, this.storeConstant.name, this.storeConstant.template, { extraParams: { clientGuid: this.$route.params.clientGuid, groupGuid: this.$route.params.groupGuid } });
        this.isGetLoading = true;
        await this.getMappingData();
        this.isGetLoading = false;
    },
    methods: {
        async callbackForm(response) {
            this.formLoading = false;
            this.formData.isFormSaved = true;
            await this.getMappingData();
            this.setIsInitialConfiguration();
            EventBus.$emit(EVENTS.REFRESH);
        },
        cancel(showConfirm) {
            this.toggleConfirmCloseSave(showConfirm);
            if (!showConfirm) {
                this.emitClose();
                this.$refs.stepper.returnToFirstStep();
            }
        },
        getExistingBenefitMapping,
        async getIntegrationDataStructure() {
            this.isPostLoading = true;
            this.formData.previousFormDataContent = cloneDeep(this.formData.content);
            await this.payrollFilesConversionStore.sendActions('getIntegrationDataStructure', { data: this.formData.content.recipients });
            this.setFormData(this.payrollFilesConversionStore.getGetter('getIntegrationDataStructure'));
            this.isPostLoading = false;
        },
        async getMappingData() {
            await this.payrollFilesConversionStore.sendActions('get', { query: { culture: LANGUAGE_CODES.getCodeByLanguage(this.$i18n.locale) } });
            this.$set(this.formData, 'content', this.payrollFilesConversionStore.getGetter('get'));
            this.$set(this.initialFormData, 'content', this.payrollFilesConversionStore.getGetter('get'));
        },
        handleClickNext(step) {
            if (step === PAYROLL_FILE_CONVERSION_STEPPER_TYPE.GROUP_BENEFITS_MAPPING && !this.isRecipientsSameAsPrevious()) {
                this.getIntegrationDataStructure();
            }
        },
        isRecipientsSameAsPrevious() {
            return isEqual(this.formData.content.recipients, this.formData?.previousFormDataContent?.recipients);
        },
        resetFormData() {
            const defaultData = {
                clientId: this.$route.params.clientGuid,
                groupId: this.$route.params.groupGuid,
                previousFormDataContent: {},
                content: {
                    groupBenefitsMapping: { useCustomMappings: false, groupBenefits: null },
                    participantsMapping: null,
                    recipients: [{ integrationType: null, mappingCode: null }],
                    taxableBenefitsMapping: { useCustomMappings: false, taxableBenefits: null },
                },
            };
            this.defaultData = { ...defaultData };
        },
        setFormData(data) {
            if (!this?.formData?.previousFormDataContent?.groupBenefitsMapping?.groupBenefits?.length) {
                this.$set(this.formData, 'content', data);
                return;
            }
            const newData = cloneDeep(data);
            newData.participantsMapping = cloneDeep(this.formData.previousFormDataContent.participantsMapping);
            newData.groupBenefitsMapping.groupBenefits = this.getExistingBenefitMapping(newData, 'groupBenefitsMapping', 'groupBenefits', this.formData.previousFormDataContent);
            newData.taxableBenefitsMapping.taxableBenefits = this.getExistingBenefitMapping(newData, 'taxableBenefitsMapping', 'taxableBenefits', this.formData.previousFormDataContent);
            this.$set(this.formData, 'content', newData);
        },
        setIsInitialConfiguration() {
            this.payrollFilesConversionStore.sendActions('updateIsInitialConfiguration', { data: { value: true } });
        },
        stepperItems() {
            const items = [];
            for (let i = 0; i < 4; i += 1) {
                const item = {
                    header: {
                        text: this.$t(`dialog.header${i}`),
                    },
                    stepper: {
                        component: getMappingStepsComponents(i),
                        props: {
                            showDialogAddParticipantForm: this.showDialogAddParticipantForm,
                            overwriteData: this.overwriteData,
                            defaultSectionFormData: { ...this.defaultData },
                            formData: this.formData,
                            initialFormData: this.initialFormData,
                        },
                    },
                };
                items.push(item);
            }
            return items;
        },
        async updateForm(key, value) {
            this.$set(this.formData.content, key, isNotNullOrUndefined(value) ? value : null);
            await this.$nextTick();
            this.isAllFormStepsValid = await this.validateAllSteps();
        },
        async validateAllSteps() {
            const validation = [];
            for (let i = 1; i <= this.stepperItems().length; i += 1) {
                // eslint-disable-next-line no-await-in-loop
                validation[i] = await this.validateStep(i);
            }
            return validation.every((isValid) => isValid);
        },
        async validateStep(step) {
            const action = get(this.$refs.stepper.$refs, `stepper_${step}.0.validateForm`);
            if (typeof action === 'function') {
                return action(true);
            }
            return true;
        },
    },
    i18n,
};
