export default {
    name: 'SButtonmenu',
    props: {
        buttonIcon: {
            type: String,
            default: '',
        },
        buttonLabel: {
            type: String,
            default: '',
        },
        color: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        isBlock: {
            type: Boolean,
            default: false,
        },
        isFab: {
            type: Boolean,
            default: false,
        },
        isIcon: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        isOutlined: {
            type: Boolean,
            default: false,
        },
        isTile: {
            type: Boolean,
            default: false,
        },
        maxWidth: {
            default: 'auto',
            type: [String, Number],
        },
        menuItems: {
            type: Array,
            default: () => [],
        },
        nameE2E: {
            type: String,
            default: '',
        },
        size: {
            type: String,
            default: 'medium',
        },
        tooltip: {
            type: String,
        },
    },
    computed: {
        disabledToolTip() {
            return typeof this.tooltip === 'undefined' || this.disabled;
        },
    },
    methods: {
        click(key) {
            this.$emit('click', key);
        },
        setsize(size) {
            if (size === 'small' && !this.small && !this.xSmall) {
                return true;
            }
            return this[size];
        },
    },
};
