import { EventBus } from 'segic_shared_front_end_utils/src/utils/event-bus';
import ComponentMixins from '@/mixins/segic-component';
import MESSAGE_TYPE from 'segic_shared_front_end_utils/src/constants/message-types';
import EVENTS from 'segic_shared_front_end_utils/src/constants/events';
export default {
    name: 'SComponentSnackbar',
    mixins: [ComponentMixins],
    data() {
        return {
            enabled: false,
            dismissible: false,
            message: '',
            type: MESSAGE_TYPE.SUCCESS,
            extraMessage: null,
        };
    },
    computed: {
        timeout() {
            return (this.dismissible) ? -1 : 5000;
        },
        icon() {
            let icon;
            switch (this.type) {
                case MESSAGE_TYPE.SUCCESS:
                    icon = 'done';
                    break;
                case MESSAGE_TYPE.ERROR:
                    icon = 'error';
                    break;
                case MESSAGE_TYPE.WARNING:
                    icon = 'error_outline';
                    break;
                default:
                    icon = null;
            }
            return icon;
        },
        messages() {
            let { message } = this;
            if (typeof this.extraMessage === 'string') {
                message = `${this.message} ${this.extraMessage}`;
            }
            return message;
        },
    },
    mounted() {
        EventBus.$on(EVENTS.SNACKBAR, this.handle);
    },
    beforeDestroy() {
        EventBus.$off(EVENTS.SNACKBAR, this.handle);
    },
    methods: {
        handle(data) {
            this.enabled = (typeof data.enabled === 'boolean') ? data.enabled : true;
            this.message = (data.message) ? data.message : '';
            this.type = (data.type) ? data.type : this.type;
            this.dismissible = (typeof data.dismissible === 'boolean') ? data.dismissible : false;
            this.extraMessage = data.extraMessage;
        },
    },
};
