import Vue from 'vue';
export const EventBus = new Vue();
export function sendEvent(event, value) {
    this.$emit('event', {
        name: 'EVENT',
        value,
        event,
    });
}
export function propagateEvent(value) {
    this.$emit('event', value);
}
export function handleAction(data) {
    emitAction.call(this, data);
}
export function emitAction(data) {
    this.$emit('emit:action', data);
}
