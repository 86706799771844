import SFieldTextarea from '@/components/form/fields/textarea.vue';
import SFieldFileInput from '@/components/form/fields/file-input.vue';
import MESSAGE_CONTENT_TYPE from 'segic_shared_front_end_utils/src/enums/message-content-type';
import DownloadMixin from '@/mixins/download';
import ComponentMixins from '@/mixins/segic-component';
import { EventBus } from 'segic_shared_front_end_utils/src/utils/event-bus';
import { forEach } from 'lodash';
import MESSAGE_STATUS from 'segic_shared_front_end_utils/src/enums/message-status';
import { formatFileName } from 'segic_shared_front_end_utils/src/utils/file';
export default {
    name: 'SNoteFiles',
    components: {
        SFieldFileInput,
        SFieldTextarea,
    },
    mixins: [
        ComponentMixins,
        DownloadMixin,
    ],
    props: {
        acceptFiles: {
            type: String,
            default: '.xlsx,.xls,.jpeg,.jpg,.doc,.docx,.pdf,.png',
        },
        appendLabelNote: {},
        appendLabelFiles: {},
        rulesFiles: {
            type: String,
            default: '',
        },
        rulesNote: {
            default: '',
        },
        labelFiles: {},
        labelNote: {},
        modelDisplayAttachments: {
            default: () => [],
        },
        modelFiles: {
            required: true,
            default: () => [],
        },
        modelNote: {
            required: true,
            default: '',
        },
        multipleFiles: {
            default: true,
        },
        name: {
            required: true,
        },
        contentType: {},
        status: {},
        forceNoDivider: {
            default: false,
        },
        forceShowFiles: {
            default: false,
        },
        forceShowNote: {
            default: false,
        },
        reverse: {
            default: false,
        },
    },
    data() {
        return {
            isShowNote: false,
            isShowFiles: false,
            firstTime: true,
            section: {
                note: 'note',
                files: 'files',
            },
        };
    },
    computed: {
        showFiles() {
            return this.isShowFiles || this.modelDisplayAttachments.length !== 0 || this.isRequired(this.rulesFiles) || this.forceShowFiles;
        },
        showNote() {
            return this.isShowNote || this.modelNote.length !== 0 || this.isRequired(this.rulesNote) || this.forceShowNote;
        },
        appendLabelNoteFormatted() {
            let label = '';
            if (!this.isRequired(this.rulesNote)) {
                label = this.$t('optional');
            }
            return label;
        },
        labelNoteFormatted() {
            let label = this.$t('messageToThePlanAdministrator');
            if (this.labelNote) {
                label = this.labelNote;
            }
            return label;
        },
        appendLabelFilesFormatted() {
            let label = '';
            if (!this.isRequired(this.rulesFiles)) {
                label = this.$t('optional');
            }
            return label;
        },
        labelFilesFormatted() {
            let label = this.$t('labels.files');
            if (this.labelFiles) {
                label = this.labelFiles;
            }
            return label;
        },
        rulesFilesFormatted() {
            let rules = this.rulesFiles;
            if (this.contentType === MESSAGE_CONTENT_TYPE.CONSTANT.PARTICIPANT_CREATION_REQUEST && this.modelDisplayAttachments.length === 0 && this.isRequired(rules)) {
                rules += '|required';
            }
            rules = rules && rules.indexOf('|') === 0 ? rules.substring(1) : rules;
            return rules;
        },
        isReverse() {
            return this.reverse || (this.isRequired(this.rulesFilesFormatted) && !this.isRequired(this.rulesNote));
        },
        hasNoteText() {
            return typeof this.$slots.noteText !== 'undefined';
        },
    },
    mounted() {
        const parentUid = this.getParentComponentUid('s-component-dialog-component');
        EventBus.$on(`CLOSE_DIALOG_COMPONENT_${parentUid}`, this.reset);
    },
    methods: {
        formatFileName,
        setFiles(files) {
            this.isShowFiles = true;
            const newFiles = [];
            forEach(files, (file) => {
                const fileName = file.name.replace(/[^a-zA-Z0-9-/_/.]/g, '');
                const newFile = new File([file], fileName);
                newFiles.push(newFile);
            });
            this.$emit('update:modelFiles', newFiles);
        },
        updateNote(value) {
            this.isShowNote = true;
            this.$emit('update:modelNote', value);
        },
        getParentComponent(componentName) {
            let component = null;
            let parent = this.$parent;
            while (parent && !component) {
                if (parent.$options.name === componentName) {
                    component = parent;
                }
                parent = parent.$parent;
            }
            return component;
        },
        getParentComponentUid(componentName) {
            let uid = '';
            const parentComponent = this.getParentComponent(componentName);
            if (parentComponent) {
                // eslint-disable-next-line no-underscore-dangle
                uid = parentComponent._uid;
            }
            return uid;
        },
        reset() {
            this.isShowNote = false;
            this.isShowFiles = false;
        },
        isRequired(rules) {
            return rules ? rules.toLowerCase().indexOf('required') !== -1 : false;
        },
        addFileToDelete(attachment) {
            this.isShowFiles = true;
            this.$emit('deleteFile', attachment.id);
        },
        showDeleteFileBtn() {
            return this.canEdit && this.status === MESSAGE_STATUS.CONSTANT.PENDING;
        },
        showSection(section) {
            if (section === this.section.files) {
                this.isShowFiles = true;
            }
            else if (section === this.section.note) {
                this.isShowNote = true;
            }
        },
    },
    i18n: {
        messages: {
            fr: {
                labels: {
                    files: 'Attacher des fichiers à la demande',
                },
                tooltips: {
                    deleteFile: 'Retirer le fichier',
                    note: 'Ajouter un message à<br/> l\'intention de l\'administrateur du régime',
                    files: 'Attacher des fichiers à la demande',
                },
            },
            en: {
                labels: {
                    files: 'Attach files to the request',
                },
                tooltips: {
                    deleteFile: 'Remove file',
                    note: 'Add a message to the plan administrator',
                    files: 'Attach files to the request',
                },
            },
        },
    },
};
