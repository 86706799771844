import SPageTitle from '@/components/ui/page-title.vue';
import storeMixin from '@/mixins/page-layout/store';
import SegicLayoutComponent from '@/mixins/segic-layout-component';
import SPayrollFilesConversionsDialog from '@/page-layouts/administrator/forms/payroll-files-conversions/dialog.vue';
import EVENTS from 'segic_shared_front_end_utils/src/constants/events';
import i18n from '@/page-layouts/administrator/forms/payroll-files-conversions/utils/locales';
import PAYROLL_CONVERSION_EVENTS from '@/page-layouts/administrator/forms/payroll-files-conversions/events';
import { EventBus } from 'segic_shared_front_end_utils/src/utils/event-bus';
import StoreExtender from '@/store/extender';
import STORE_CONSTANT_NAMES from '@/store/names';
import PayrollFilesConversion from '@/store/payroll-files-conversion';
import { isConfigurationStatusUninitialized } from '@/types/PayrollFilesConversionConfigurationStatus';
import SPayrollFilesConversionsDialogButton from './payroll-files-conversion-dialog-button.vue';
export default {
    name: 'SearchParticipant',
    components: {
        SPageTitle,
        SPayrollFilesConversionsDialog,
        SPayrollFilesConversionsDialogButton,
    },
    mixins: [
        SegicLayoutComponent,
        storeMixin,
    ],
    data() {
        return {
            EVENTS,
            showPayrollFilesConversionsDialog: false,
            payrollFilesConversionStore: null,
            isConfigurationStatusCreate: false,
        };
    },
    computed: {
        isPayrollFilesConversionButtonDisabled() {
            return !this.payrollFilesConversionStore || !this.payrollFilesConversionStore.getGetter('getConfigurationStatus') || this.payrollFilesConversionStore.getGetter('getConfigurationStatusIsLoading');
        },
    },
    async beforeMount() {
        EventBus.$on(PAYROLL_CONVERSION_EVENTS.OPEN_PAYROLL_FILES_CONVERSION_SETUP_MODAL, this.handleShowPayrollFilesConversionDialog);
        EventBus.$on(EVENTS.REFRESH, () => { this.getConfigurationStatus(); });
        await this.getConfigurationStatus();
    },
    methods: {
        async getConfigurationStatus() {
            this.payrollFilesConversionStore = new StoreExtender(this, STORE_CONSTANT_NAMES.PAYROLL_SYSTEM_CONVERSION.BULK_CERTIFICATE_MAPPING_CONFIGURATION_STATUS, PayrollFilesConversion);
            await this.payrollFilesConversionStore.sendActions('getConfigurationStatus');
            const { configurationStatus } = this.payrollFilesConversionStore.getGetter('getConfigurationStatus');
            this.isConfigurationStatusCreate = isConfigurationStatusUninitialized(configurationStatus);
        },
        handleClosePayrollFilesConversionDialog() {
            this.showPayrollFilesConversionsDialog = false;
        },
        handleShowPayrollFilesConversionDialog() {
            this.showPayrollFilesConversionsDialog = true;
        },
    },
    i18n,
};
