import { forEach } from 'lodash';
import { EventBus } from './event-bus';
import LocalStorageManager from './localstorage';
export let featureFlags = {};
const localFeatures = LocalStorageManager.get('features', true);
if (localFeatures) {
    setFeatureFlagValue(localFeatures);
}
function setFeatureFlagValue(data) {
    const newData = {};
    forEach(data, (value) => {
        newData[value.key] = value.value;
    });
    featureFlags = newData;
}
EventBus.$on('featureFlags.update', setFeatureFlagValue);
