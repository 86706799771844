/* eslint-disable no-unused-vars, no-underscore-dangle */
import { fileMaxSizeValidate } from '@/plugins/validations';
import I18N from '@/plugins/vue-i18n';
import { currencyWithoutSeparator } from '@/utils/helpers';
import { TOKENS } from '@/utils/mask';
import moment from 'moment';
import { isDateLessThanXYearsAgo, isDateXYearsAgo } from 'segic_shared_front_end_utils/src/utils/moment';
import validateSIN from 'segic_shared_front_end_utils/src/utils/validate-sin';
import { configure, extend, setInteractionMode, ValidationObserver, ValidationProvider, } from 'vee-validate';
import { alpha_num as alphaNum, email, integer, length, max, max_value as maxValue, min, min_value as minValue, required, } from 'vee-validate/dist/rules';
import Vue from 'vue';
// eslint-disable-next-line import/prefer-default-export
const configuration = {
    // this will be used to generate messages with the vue-18n plugin.
    defaultMessage: (field, values) => {
        let validationMessage = field;
        if (I18N.messages[I18N.locale].validation[field]) {
            values._field_ = I18N.t(`validation.${field}`);
            validationMessage = I18N.t(`validation.${values._rule_}`, values);
        }
        else {
            validationMessage = I18N.t(`validation.${values._rule_}`, values);
        }
        return validationMessage;
    },
};
configure(configuration);
// Vee-validate does not come with  all rules installed to minimize the bundle size.
extend('email', email);
extend('integer', integer);
extend('length', length);
extend('min_value', minValue);
extend('max_value', maxValue);
extend('required', required);
extend('alpha_num', alphaNum);
extend('max', max);
extend('min', min);
// custom validation
const dateCustomValidation = {
    validate: (value) => /^([12]\d{3}[-]*(0[1-9]|1[0-2])[-]*(0[1-9]|[12]\d|3[01]))$/.test(value),
};
extend('date', dateCustomValidation);
// Postal code
let postalCodeRegEx = `^${TOKENS.C.pattern}${TOKENS['#'].pattern}${TOKENS.D.pattern}[ ]?${TOKENS['#'].pattern}${TOKENS.D.pattern}${TOKENS['#'].pattern}$`;
postalCodeRegEx = new RegExp(postalCodeRegEx.replace(/\//g, ''));
const postalCodeValidation = {
    validate: (value) => postalCodeRegEx.test(value),
};
extend('postalCode', postalCodeValidation);
// Phone
const phoneValidation = {
    validate: (value) => /^[(]?(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})?\s*$/.test(value),
};
extend('phone', phoneValidation);
// Money
const money = {
    validate: (value) => {
        const val = value.toString().replace('$', '').replace(/\s/g, '');
        const pattern = '^\\$?([0-9]{1,3}[, ]([0-9]{3}[, ])*[0-9]{3}|[0-9]+)(\\.[0-9]?[0-9]?)?$';
        const rx = new RegExp(pattern, 'g');
        const isValid = rx.test(val);
        return isValid;
    },
};
extend('money', money);
// IsSame
const isSame = {
    validate: (value, { otherValue }) => {
        const inputValue = currencyWithoutSeparator(value, true);
        const valueToCompare = currencyWithoutSeparator(otherValue, true);
        return inputValue !== valueToCompare;
    },
    params: ['otherValue'],
};
extend('isSame', isSame);
// isDateBeforeEffectiveDate
const isDateBeforeEffectiveDate = {
    validate: (value, { date }) => moment(date).isSameOrBefore(value),
    params: ['date'],
};
extend('isDateBeforeEffectiveDate', isDateBeforeEffectiveDate);
const isDateBeforeMax = {
    validate: (value, { date }) => moment(value).isBefore(date),
    params: ['date'],
};
extend('isDateBeforeMax', isDateBeforeMax);
const isDateAfter = {
    validate: (value, { date }) => moment(value).isAfter(date),
    params: ['date'],
};
extend('isDateAfter', isDateAfter);
const isDateNotInFuture = {
    validate: ({ date }) => moment().isSameOrAfter(date),
    params: ['date'],
};
extend('isDateNotInFuture', isDateNotInFuture);
const isDateMoreThanYears = {
    validate: (value, { age }) => isDateXYearsAgo(value, age),
    params: ['date', 'age'],
};
const isDateLessThanYears = {
    validate: (value, { age }) => isDateLessThanXYearsAgo(value, age),
    params: ['date', 'age'],
};
extend('spouseBirthDateByAge', isDateMoreThanYears);
extend('participantBirthDateByAge', isDateMoreThanYears);
extend('maxAge', isDateLessThanYears);
export function isMaxMonthsInFuture(value, months) {
    const currentDate = moment();
    const futureDate = currentDate.add(months, 'months');
    const checkDate = moment(value);
    const isMoreThan12MonthsInFuture = checkDate.isAfter(futureDate);
    return !isMoreThan12MonthsInFuture;
}
const maxMonthsInFuture = {
    validate: (value, { months }) => isMaxMonthsInFuture(value, months),
    params: ['months'],
};
extend('maxMonthsInFuture', maxMonthsInFuture);
const isSinValid = {
    validate: (nas) => validateSIN(nas),
};
extend('Sin', isSinValid);
// File size
const fileMaxSize = {
    validate: (files, { maxFileSize }) => fileMaxSizeValidate(files, { maxFileSize }),
    params: ['maxFileSize'],
};
extend('fileMaxSize', fileMaxSize);
extend('isTrue', { validate: (value) => !!value });
extend('anyFieldInteger', {
    ...integer,
});
extend('anyFieldMax', {
    ...max,
});
extend('anyFieldMin', {
    ...min,
});
extend('anyFieldAlphaNum', { ...alphaNum });
const unique = {
    validate(value, { otherValues }) {
        return !otherValues.includes(value);
    },
    params: ['otherValues'],
};
extend('unique', unique);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
setInteractionMode('lazy');
