import SPageTitle from '@/components/ui/page-title.vue';
import SFieldSelect from '@/components/form/fields/select.vue';
import SFieldDatepicker from '@/components/form/fields/datepicker.vue';
import SegicCommons from '@/mixins/segic-commons';
import { checkHasDifferences } from 'segic_shared_front_end_utils/src/utils/data-compare';
import StoreExtender from '@/store/extender';
import { EventBus } from 'segic_shared_front_end_utils/src/utils/event-bus';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import MESSAGE_TYPE from 'segic_shared_front_end_utils/src/constants/message-types';
import HTTP_CODES from 'segic_shared_front_end_utils/src/constants/http-codes';
import EVENTS from 'segic_shared_front_end_utils/src/constants/events';
import STORE_CONSTANT_NAMES from '@/store/names';
import PlanMembersStore from '@/store/participants/planMembers';
import SimulationPlanMembers from '@/store/simulation/plan-members';
import { getParticipantFormDefaultData } from './utils/formDataHelpers';
export default {
    name: 'SimulationHeader',
    components: {
        SFieldDatepicker,
        SFieldSelect,
        SPageTitle,
    },
    mixins: [
        SegicCommons,
    ],
    data() {
        return {
            defaultData: getParticipantFormDefaultData.call(this),
            initialFormData: {},
            formData: getParticipantFormDefaultData.call(this),
            formHasChangedValues: {},
            inputsDisabled: false,
            isInitial: true,
            searchType: 0,
            planMembersIsLoading: false,
            planMembers: [],
            simulationPlanMembersIsLoading: false,
        };
    },
    computed: {
        dateRules() {
            return `required|isDateBeforeMax:${this.getMaxDate()}`;
        },
        isLoading() {
            return this.simulationPlanMembersIsLoading || this.planMembersIsLoading;
        },
        today() {
            return moment().format(this.$t('format.date.view'));
        },
    },
    watch: {
        formData: {
            handler() {
                checkHasDifferences(this.defaultData, this.formData, this.initialFormData, this.formHasChangedValues);
                this.formHasChangedValues = cloneDeep(this.formHasChangedValues);
                EventBus.$emit('participantFormHasChanged', this.formHasChangedValues.hasChanged);
            },
            deep: true,
        },
    },
    mounted() {
        this.getPlanMembers();
        EventBus.$on('simulationIsStarted', this.disableInputs);
        EventBus.$on('simulationIsDone', this.enableInputs);
        EventBus.$on('simulationIsDone', this.setInitialData);
        EventBus.$on('resetProfile', this.resetProfile);
        EventBus.$on('reloadProfile', (baseParticipantData, isInitialCategory) => {
            const extraQuery = isInitialCategory ? {} : { categoryId: baseParticipantData.categoryId };
            this.getParticipantProfile(baseParticipantData, extraQuery);
        });
        this.getParticipantFromQueryParams();
        this.setFormData();
    },
    methods: {
        setFormData() {
            this.formData.groupIds = [this.$route.params.groupGuid];
            this.formData.insuredRelationships = [0];
            this.formData.currentParticipantStatus = true;
        },
        async getPlanMembers() {
            this.planMembersIsLoading = true;
            const extraParams = {
                clientGuid: this.$route.params.clientGuid,
                groupGuid: this.$route.params.groupGuid,
            };
            const planMembersStore = new StoreExtender(this, STORE_CONSTANT_NAMES.PLAN_MEMBERS.LIST, PlanMembersStore, { extraParams });
            await planMembersStore.sendActions('get', this.formData);
            this.planMembers = planMembersStore.getGetter('getPlanMembersSelect');
            this.planMembersIsLoading = false;
        },
        enableInputs() {
            this.inputsDisabled = false;
        },
        disableInputs() {
            this.inputsDisabled = true;
        },
        getParticipantFromQueryParams() {
            if (this.$route.query.participantGuid && this.$route.query.certificateNumber) {
                this.getParticipantProfile({
                    certificateNumber: this.$route.query.certificateNumber,
                    id: this.$route.query.participantGuid,
                });
            }
        },
        async getParticipantProfile(baseParticipantData, extraQuery = {}) {
            this.simulationPlanMembersIsLoading = true;
            EventBus.$emit('gettingParticipantProfile');
            if (this.isInitial) {
                this.formData.participant = cloneDeep(baseParticipantData);
                this.formData.simulationDate = this.getDate();
                this.initialFormData = cloneDeep(this.formData);
            }
            const extraParams = {
                clientGuid: this.$route.params.clientGuid,
                groupGuid: this.$route.params.groupGuid,
                participantGuid: this.formData.participant.id,
                participantCode: this.formData.participant.certificateNumber,
            };
            const payload = {
                query: {
                    simulationDate: this.getDate(),
                },
            };
            if (extraQuery.categoryId) {
                payload.query.categoryId = extraQuery.categoryId;
            }
            const simulationPlanMemberStore = new StoreExtender(this, STORE_CONSTANT_NAMES.PLAN_MEMBERS.SIMULATION, SimulationPlanMembers, {
                extraParams,
                isHandlingErrors: true,
            });
            try {
                await simulationPlanMemberStore.sendActions('get', payload);
                const simulationPlanMember = simulationPlanMemberStore.getGetter('get');
                if (simulationPlanMember) {
                    if (this.isInitial) {
                        EventBus.$emit('handleParticipantProfile', {
                            participant: baseParticipantData,
                            simulationPlanMember,
                            simulationDate: this.getDate(),
                        });
                        this.isInitial = false;
                    }
                    else {
                        EventBus.$emit('updateParticipantBenefits', {
                            mandatoryBenefits: simulationPlanMember.mandatoryBenefits,
                            optionalBenefits: simulationPlanMember.optionalBenefits,
                        });
                    }
                }
                this.simulationPlanMembersIsLoading = false;
            }
            catch (e) {
                if (e.code === HTTP_CODES.NOT_FOUND) {
                    EventBus.$emit('handleParticipantError');
                }
                else {
                    EventBus.$emit(EVENTS.SNACKBAR, { message: e.message, type: MESSAGE_TYPE.ERROR });
                }
                this.simulationPlanMembersIsLoading = false;
            }
        },
        getDate() {
            return this.formData.simulationDate || this.today();
        },
        getMaxDate() {
            const sixMonths = moment().add(6, 'months');
            return moment(sixMonths).format(this.$t('format.date.view'));
        },
        async resetProfile() {
            await this.handleParticipantChange(this.formData.participant);
            EventBus.$emit('reloadSimulation');
        },
        async handleDateChange(date) {
            if (date === this.formData.simulationDate) {
                return;
            }
            await this.$refs.observer.validate();
            this.formData.simulationDate = date;
            if (this.formData.participant && this.formData.participant.id) {
                this.handleParticipantChange(this.formData.participant);
            }
        },
        async handleParticipantChange(baseParticipantData) {
            this.isInitial = true;
            await this.$refs.observer.validate();
            if (this.hasErrors()) {
                EventBus.$emit('formValidationHandling', { hasErrors: true });
                return;
            }
            this.removeQueryParams();
            await this.getParticipantProfile(baseParticipantData);
        },
        removeQueryParams() {
            this.$router.replace({ query: {} }).catch((error) => {
                if (error.name !== 'NavigationDuplicated') {
                    throw error;
                }
            });
        },
        hasErrors() {
            return Object.values(this.$refs.observer.errors).some((error) => error.length);
        },
        setInitialData() {
            this.initialFormData = cloneDeep(this.formData);
        },
    },
};
