import ErrorCodes from '@/store/change-requests-attachments/error-codes';
import ChangeRequestsAttachmentsSingleStore from '@/store/change-requests-attachments/single';
import StoreExtender from '@/store/extender';
import STORE_CONSTANT_NAMES from '@/store/names';
import { isFileRequired } from '@/utils/checker';
import { EventBus } from 'segic_shared_front_end_utils/src/utils/event-bus';
import { guid } from '@/utils/helpers';
import { filter, isEqual } from 'lodash';
import EVENTS from 'segic_shared_front_end_utils/src/constants/events';
export default {
    data() {
        return {
            attachmentsStore: null,
            messagingStore: null,
            requestIdToDelete: null,
            filesDownloading: [],
            showDialogDeleteFileConfirm: false,
        };
    },
    methods: {
        setFiles(file) {
            if (!file) {
                this.formData.attachments = [];
            }
            else {
                this.formData.attachments.push(file);
            }
        },
        fileToDelete(id) {
            this.formData.displayAttachments = filter(this.formData.displayAttachments, (item) => item.id !== id);
            this.formData.deleteAttachmentIds.push(id);
        },
        moreStores(promises) {
            const extraParams = {
                messagingGuid: this.id,
                attachmentGuid: guid(),
            };
            this.attachmentsStore = new StoreExtender(this, STORE_CONSTANT_NAMES.ATTACHMENTS.SINGLE, ChangeRequestsAttachmentsSingleStore, { extraParams });
            const attachmentsAreEqual = isEqual(this.initialFormData.attachments, this.formData.attachments);
            if (this.id && !attachmentsAreEqual) {
                promises.push(this.uploadFiles(this.id, this.formData.attachments));
            }
            if (this.id) {
                if ((this.formData.deleteAttachmentIds.length !== 0 && !isFileRequired(this.formData))
                    || (this.formData.deleteAttachmentIds.length !== 0 && promises.length === 0)) {
                    promises.push(this.deleteFiles());
                }
            }
            return promises;
        },
        emitError(data) {
            this.$emit(EVENTS.ERROR, data);
        },
        hideErrorMessage() {
            this.emitError({
                show: false,
            });
        },
        isVirus(internalCode) {
            return ErrorCodes.VIRUS === internalCode;
        },
        attachmentsError(responseError, attachments) {
            if (this.isVirus(responseError.internalCode)) {
                const errorAlert = {
                    message: this.setMessageFileError(responseError, attachments),
                    dismissible: true,
                    show: true,
                };
                this.emitError(errorAlert);
            }
            this.emitLoading(false);
        },
        callbackForm(response, alertData, attachments, isCreatedRequest, closeModal = true) {
            let emitSuccess = true;
            if (isCreatedRequest && attachments.length !== 0) {
                const { data } = response;
                emitSuccess = false;
                this.uploadFiles(data.id, attachments).then((responseFile) => {
                    this.emitSuccess(responseFile);
                    this.emitClose();
                    EventBus.$emit(EVENTS.SNACKBAR, alertData);
                }).catch((responseError) => {
                    this.emitLoading(true);
                    this.store.sendActions('delete', { requestGuid: data.id }).then(() => {
                        this.attachmentsError(responseError, attachments);
                    });
                });
            }
            if (this.formData.deleteAttachmentIds && this.formData.deleteAttachmentIds.length !== 0 && isFileRequired(this.formData) && this.formData.displayAttachments.length === 0) {
                emitSuccess = false;
                // eslint-disable-next-line no-shadow
                this.deleteFiles().then((response) => {
                    this.emitSuccess(response);
                    EventBus.$emit(EVENTS.SNACKBAR, {
                        message: response.message,
                        type: response.messageType,
                    });
                    this.emitClose();
                });
            }
            if (emitSuccess) {
                EventBus.$emit(EVENTS.SNACKBAR, alertData);
                if (closeModal) {
                    this.emitSuccess(response);
                    this.emitClose();
                }
                else {
                    this.emitLoading(false);
                }
            }
        },
        setMessageFileError(responseFile, attachments, traductionKey = 'api.file.virusCreated') {
            let files = '<ul>';
            attachments.forEach((item) => {
                if (responseFile.message.indexOf(item.name) !== -1) {
                    files += `<li>${item.name}</li>`;
                }
            });
            files += '</ul>';
            return this.$t(traductionKey, { files });
        },
        deleteFiles() {
            const payload = {
                params: {
                    requestGuid: this.id,
                },
                data: this.formData.deleteAttachmentIds,
            };
            return this.attachmentsStore.sendActions('delete', payload);
        },
        uploadFiles(requestGuid, attachments) {
            const payload = {
                params: {
                    requestGuid,
                },
                data: attachments,
            };
            return this.attachmentsStore.sendActions('create', payload);
        },
    },
};
