import SDatatableBase from '@/components/datatables/base.vue';
import SFieldCheckbox from '@/components/form/fields/checkbox.vue';
import SFieldText from '@/components/form/fields/text.vue';
import SStatus from '@/components/ui/status.vue';
import ComponentMixins from '@/mixins/segic-component';
import StepperFormMixin from '@/page-layouts/administrator/forms/add-participant/byForm/sections/mixins';
import SMappingCode from '@/page-layouts/administrator/forms/payroll-files-conversions/components/mapping-code.vue';
import SDialogLoader from '@/page-layouts/administrator/forms/payroll-files-conversions/dialog-loader.vue';
import i18n from '@/page-layouts/administrator/forms/payroll-files-conversions/utils/locales';
import { getMappingValueByCode, updateIsDisabled } from '@/page-layouts/administrator/forms/payroll-files-conversions/utils/mapping';
import SSettingsHeader from '@/page-layouts/administrator/forms/payroll-files-conversions/settings/settings-header.vue';
import SCertificateNumbersMappingBulkDialog from '@/page-layouts/administrator/forms/payroll-files-conversions/certificate-numbers-bulk-mapping/index.vue';
import BenefitsMappingMixin from '../utils/benefits-mapping-mixin';
import SIsDisabled from './is-disabled-tooltip.vue';
export default {
    name: 'SPageLayoutsParticipantsAddParticipantFormSectionsContactDetails',
    components: {
        SCertificateNumbersMappingBulkDialog,
        SSettingsHeader,
        SIsDisabled,
        SFieldCheckbox,
        SMappingCode,
        SDatatableBase,
        SFieldText,
        SStatus,
        SDialogLoader,
    },
    mixins: [
        BenefitsMappingMixin,
        ComponentMixins,
        StepperFormMixin,
    ],
    props: {
        canShowMappingCodeWarning: {
            default: false,
            type: Boolean,
        },
        formData: {
            required: true,
            type: Object,
        },
        initialFormData: {
            type: Object,
            required: true,
        },
        isStepLoading: {
            default: false,
            type: Boolean,
        },
    },
    data() {
        return {
            conditionalHeaders: [
                {
                    component: SIsDisabled,
                    value: 'isDisabled',
                },
            ],
            baseHeaders: [
                {
                    text: this.$t('benefit'),
                    value: 'name',
                },
            ],
            items: [],
            headers: [],
            mappingCodeHeaders: [],
            mappingType: 'groupBenefitsMapping',
            mappingKey: 'groupBenefits',
        };
    },
    computed: {
        mappingCodeLabel() {
            return this.$t('earningNumber');
        },
        rowMappingsValidationRules() {
            const mappings = {};
            this.items.forEach((item) => {
                mappings[item.id] = item.mappings.map(() => ({
                    required: !item.isDisabled,
                    anyFieldInteger: true,
                    anyFieldMax: 3,
                    anyFieldMin: 3,
                }));
            });
            return mappings;
        },
    },
    watch: {
        'formData.content.groupBenefitsMapping': {
            handler() {
                this.headers = this.getHeaders();
                this.items = this.getItems();
            },
            deep: true,
        },
    },
    beforeMount() {
        this.headers = this.getHeaders();
        this.items = this.getItems();
    },
    methods: {
        getInitialModel(index, header) {
            if (this.initialFormData.content.groupBenefitsMapping.useCustomMappings && this.initialFormData?.content?.groupBenefitsMapping?.groupBenefits.length) {
                return getMappingValueByCode(this.initialFormData.content.groupBenefitsMapping.groupBenefits[index].mappings, header.integrationType, header.recipientMappingCode);
            }
            return this.formData.content.groupBenefitsMapping.groupBenefits[index].defaultMappingCode;
        },
        getItems() {
            if (!this.formData.content.groupBenefitsMapping.groupBenefits) {
                return [];
            }
            const items = this.formData.content.groupBenefitsMapping.groupBenefits.map(({ defaultMappingCode, description, status, id, isDisabled, mappings, prefix, }) => ({
                defaultMappingCode,
                id,
                isDisabled,
                mappings,
                name: { description: description[this.$i18n.locale], status },
                prefix,
            }));
            this.addItemRecipientMappingCode(items);
            return items;
        },
        getMappingValueByCode,
        handleUpdateIsDisabled(value, id) {
            updateIsDisabled(!value, this.mappingType, this.mappingKey, id, this.formData.content);
            this.$emit('update:model', this.mappingType, this.formData.content[this.mappingType]);
        },
    },
    i18n,
};
