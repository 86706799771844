import SDatatableBase from '@/components/datatables/base.vue';
import SFieldSelect from '@/components/form/fields/select.vue';
import ComponentMixins from '@/mixins/segic-component';
import StepperFormMixin from '@/page-layouts/administrator/forms/add-participant/byForm/sections/mixins';
import SPageLayoutOnBoardingCellParticipantName from '@/page-layouts/administrator/forms/payroll-files-conversions/certificate-numbers-bulk-mapping/name.vue';
import SMappingCode from '@/page-layouts/administrator/forms/payroll-files-conversions/components/mapping-code.vue';
import i18n from '@/page-layouts/administrator/forms/payroll-files-conversions/utils/locales';
import { isNullOrUndefined } from '@/utils/helpers';
import { cloneDeep, tail } from 'lodash';
export default {
    name: 'SPageLayoutsParticipantsAddParticipantFormSectionsContactDetails',
    components: {
        SFieldSelect,
        SPageLayoutOnBoardingCellParticipantName,
        SDatatableBase,
        SMappingCode,
    },
    mixins: [
        ComponentMixins,
        StepperFormMixin,
    ],
    props: {
        formData: {
            type: Object,
            required: true,
        },
        initialFormData: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            items: [],
        };
    },
    computed: {
        headers() {
            return [
                {
                    text: this.$t('payrollFile'),
                    value: 'recipient',
                },
                {
                    text: this.$t('payrollSystem'),
                    value: 'integrationType',
                },
                {
                    text: this.$t('companyNumber'),
                    value: 'mappingCode',
                },
            ];
        },
    },
    watch: {
        'formData.content.recipients': {
            handler() {
                this.items = this.getItems();
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {
        dispatchEvent({ key, value, index }) {
            if (!isNullOrUndefined(value)) {
                const recipients = cloneDeep(this.formData.content.recipients);
                recipients[index][key] = value;
                this.$emit('update:model', 'recipients', recipients);
                this.$nextTick(async () => {
                    this.$emit('update:recipientsWarnings', { key, value, index });
                    const isFormValid = await this.validateForm(true);
                    this.emitEnabledNextBtn(this.stepIndex, isFormValid);
                });
            }
        },
        getDivisionNames(divisions) {
            return divisions.map((division) => division[this.$i18n.locale]).join(', ');
        },
        getIntegrationTypes() {
            const result = this.formData.content.payrollIntegrations.map(({ description, type }) => ({
                description: description[this.$i18n.locale],
                type,
            }));
            return [...tail(result), result[0]];
        },
        getIntegrationTypePrefix(integrationType) {
            return this.getIntegrationTypePrefixByIntegrationType(integrationType);
        },
        getIntegrationTypePrefixByIntegrationType(integrationType) {
            const selectedIntegrationType = this.formData.content.payrollIntegrations.find((item) => item.type === integrationType);
            return selectedIntegrationType ? selectedIntegrationType?.prefix : '';
        },
        getItems() {
            if (!this?.formData?.content?.recipients?.length) {
                return [];
            }
            return cloneDeep(this.formData.content.recipients);
        },
    },
    i18n,
};
