import { PAYROLL_FILE_CONVERSION_STEPPER_TYPE, } from '@/page-layouts/administrator/forms/payroll-files-conversions/utils/form.types';
import setWarnings from '@/page-layouts/administrator/forms/payroll-files-conversions/utils/warnings/setWarnings';
import { isMissingMapping, isMissingParticipantMapping } from '@/store/payroll-files-conversion/entities';
export default async function handleWarnings() {
    if (this.tab !== PAYROLL_FILE_CONVERSION_STEPPER_TYPE.RECIPIENTS_MAPPING) {
        const isTabFormValid = await this.validateStep(this.tab);
        this.formData.content.isMissingMapping[this.tab] = this.tab === PAYROLL_FILE_CONVERSION_STEPPER_TYPE.CERTIFICATES_MAPPING ? !isTabFormValid || isMissingParticipantMapping(this.formData.content.participantsMapping.participants) : !isTabFormValid;
        this.isAllFormStepsValid = await this.validateAllSteps() && !isMissingMapping(this.formData.content.groupBenefitsMapping, 'groupBenefits') && !isMissingMapping(this.formData.content.taxableBenefitsMapping, 'taxableBenefits');
        await setWarnings.call(this);
    }
}
